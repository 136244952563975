import './App.css';
import './styles/global.css';
import Header from './components/header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Client from './pages/client';
import Contact from './pages/contact';
import Employee from './pages/employee';
import Service from './pages/service';
import React, { useRef, useEffect, useState } from 'react';
import FullScreenVideo from './components/FullScreenVid';
import AutoPlaySilentVideo from './components/video';
import ServiceCard from './components/serviceCard';
import VideoCarousel from './components/VideoCarousel';

function Home() {

  const textRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view'); 
          }
        });
      },
      { threshold: 0.1 } 
    );
  
    const elements = document.querySelectorAll('.floating-text');
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="App mt-16">
      <FullScreenVideo/>
      <Header/>
      <div className="h-[150px] bg-green-800"></div>
      <div className='bg-green-800 text-white'>
      <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text mx-10px">
        <div className="flex flex-col items-center min-w-4/12">
          <div className='text-3xl p-4'>
            <p class='kanit-bold'>About Us</p>
            
          </div>
          <div div className='p-4 md:max-w-lg w-[80%]'>
          <p class='ubuntu-regular'>
          Connecting traditional industry with renewable energy, we offer cutting-edge solutions to help business grow while saving the environment. Starting with the wind energy, we have already expanded our expertise on the solar energy to work closely with clients in order to navigating them through the renewable energy.
          </p>
          </div>
          <a href="/about">
            <button className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
            Learn More
            </button>
          </a>
        </div>
      </div>
      </div>
      <div className="h-[150px] bg-green-800"></div>
      <div className="h-[150px]"></div>

      <div className='z-[10] relative'>

      <div className="flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text mx-10px">
        <div className='md:w-3/12 flex justify-center'>

        <div className='w-full max-w-[500px] min-h-[200px] mx-auto sm:w-[90%] sm:ml-[5%] sm:mr-[5%]'>
        <img src={`${process.env.PUBLIC_URL}/water.jpg`} alt="service" />
        </div>
        </div>
        <div className="flex flex-col items-center min-w-4/12">
          <div className='text-3xl p-4'>
          <p class='kanit-bold'>Our Services</p>
          </div>
          <div div className='p-4 md:max-w-lg w-[80%]'>
          <p class='ubuntu-regular'>
          Our services consists of renewable energy consulting, wind & solar data analytics, energy transition roadmap, customized software solutions, and renewable energy partnerships & collaborations
            </p>
          </div>
          <a href="/service">
           <button className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
            Learn More
           </button>
          </a>
        </div>
      </div>
      </div>
      <div className="h-[150px]"></div>
      <div className="h-[150px] bg-gray-200"></div>
      <div className="mx-10px bg-gray-200">
        <div className='flex flex-col md:flex-row justify-center items-center transition-opacity duration-1000 ease-out transform opacity-0 translate-y-12 floating-text'>
          <div className='w-3/12 flex justify-center'>
            <div className='w-full max-w-[500px] min-h-[200px] mx-auto sm:w-[90%] sm:ml-[5%] sm:mr-[5%]'>
            <img src={`${process.env.PUBLIC_URL}/chicken.png`} alt="contact" />
            </div>
          </div>
          <div className="flex flex-col items-center min-w-4/12">
            <div className='text-3xl p-4'>
            <p class='kanit-bold'>Contact Us</p>
            </div>
            <div div className='p-4 md:max-w-lg w-[80%]'>
              <p class='ubuntu-regular'>
              Have questions or feedback? We’d love to hear from you! Feel free to reach out, and our team will get back to you as soon as possible.
              </p>
            </div>
            <button className='focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800'>
              <a href="/contact">
              Contact Us
              </a>
            </button>
          </div>
        </div>

        
      </div>

      <div className="h-[250px] bg-gray-200"></div>
    </div>
  );
}

function Home_alt() {

  const socialLinks = [
    {
      name: "Facebook",
      url: "https://facebook.com",
      svg: (
        <svg className="w-6 h-6" fill="#166534" viewBox="0 0 24 24">
          <path d="M22,12A10,10,0,1,0,10,22V14H7v-2h3V9.5A4.5,4.5,0,0,1,15.5,5H19V7H15.5A2.5,2.5,0,0,0,13,9.5V12h6v2H13v8A10,10,0,0,0,22,12Z" />
        </svg>
      ),
      bg: "bg-blue-600 hover:bg-blue-700",
    },
    {
      name: "Twitter",
      url: "https://twitter.com",
      svg: (
        <svg className="w-6 h-6" fill="#166534" viewBox="0 0 24 24">
          <path d="M22,5.69A8.44,8.44,0,0,1,19.36,6,4.15,4.15,0,0,0,22,3.09a8.56,8.56,0,0,1-2.69,1A4.11,4.11,0,0,0,12.15,8a11.66,11.66,0,0,1-8.46-4.29,4.14,4.14,0,0,0,1.28,5.53A4,4,0,0,1,2,8.35v.05a4.14,4.14,0,0,0,3.3,4.06,4.14,4.14,0,0,1-1.85.07A4.15,4.15,0,0,0,7.68,16a8.31,8.31,0,0,1-5.14,1.77A8.5,8.5,0,0,1,2,17.61,11.62,11.62,0,0,0,8.29,20c7.55,0,11.68-6.26,11.68-11.7,0-.18,0-.36,0-.54A8.31,8.31,0,0,0,22,5.69Z" />
        </svg>
      ),
      bg: "bg-blue-400 hover:bg-blue-500",
    },
    {
      name: "Instagram",
      url: "https://instagram.com",
      svg: (
        <svg className="w-6 h-6" fill="#166534" viewBox="0 0 24 24">
          <path d="M7,2H17a5,5,0,0,1,5,5V17a5,5,0,0,1-5,5H7A5,5,0,0,1,2,17V7A5,5,0,0,1,7,2ZM17,4H7A3,3,0,0,0,4,7V17a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V7A3,3,0,0,0,17,4Zm-5,5A4,4,0,1,1,8,13,4,4,0,0,1,12,9Zm0,2A2,2,0,1,0,14,13,2,2,0,0,0,12,11ZM16.5,7A1.5,1.5,0,1,1,15,8.5,1.5,1.5,0,0,1,16.5,7Z" />
        </svg>
      ),
      bg: "bg-pink-500 hover:bg-pink-600",
    },
  ];

  const textRef = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view'); 
          }
        });
      },
      { threshold: 0.1 } 
    );
  
    const elements = document.querySelectorAll('.floating-text');
    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);
  return (
      <div>
        <Header/>
        <div className="h-[64px]"></div>
        <VideoCarousel/>
        <div className="top-[500px] md:top-[900px] left-0 w-full sm:h-[1100px] h-[600px] bg-green-800 flex-cols justify-center">
          <p className="text-white text-lg md:text-5xl font-bold text-center pt-5">
            Who we are
          </p>
          <div className="flex items-end justify-center gap-5 mx-5 my-5">
            <div className="w-[300px] md:w-[600px]">
              <p className="text-white text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold text-center">
                Technical Problem Solvers
              </p>
              <img className='pt-5' src={`${process.env.PUBLIC_URL}/water.jpg`} alt="service" />
            </div>
            <div className="w-[300px] md:w-[600px]">
              <p className="text-white text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold text-center">
                Strategic Developers
              </p>
              <img className='pt-5' src={`${process.env.PUBLIC_URL}/water.jpg`} alt="service" />
            </div>
            <div className="w-[300px] md:w-[600px]">
              <p className="text-white text-3xl md:text-4xl lg:text-5xl xl:text-7xl font-bold text-center">
                Resource Assessment Specialists
              </p>
              <video
                className='pt-5'
                loop
                autoPlay
                muted
                playsInline>
                <source src={`${process.env.PUBLIC_URL}/about_vid3.mp4`} type="video/mp4"/>
            </video>
            </div>
          </div>
          <div className='flex justify-center'>
          <a href="/about"><button className="text-white text-3xl rounded-lg border-2 border-white p-3 mt-5">Discover Who We Are</button> </a>
          </div> 
        </div>
        <div className="relative left-0 w-full sm:h-[1100px] h-[600px] flex-cols justify-center">
          <p className="text-green-900 text-lg md:text-5xl font-bold text-center pt-5">
            Our Services
          </p>
          <div className="flex items-end justify-center gap-5 mx-5 my-5">
            <div className="w-[150px] sm:w-[250px] lg:w-[350px] xl:w-[400px]">
              <p className="text-green-900 text-3xl md:text-4xl font-bold text-center">
                Wind Development
              </p>
              <div className=''>
                <p className='pt-5 text-center text-lg w-[150px] sm:w-[250px] lg:w-[350px] xl:w-[400px]'>
                We’re driven by a mission to shape a sustainable, innovative future through specialized consulting in wind and solar energy development.
                </p>
              </div>
              <video
                className='pt-5'
                loop
                autoPlay
                muted
                playsInline>
                <source src={`${process.env.PUBLIC_URL}/service_vid_1.mp4`} type="video/mp4"/>
              </video>
            </div>
            <div className="w-[150px] sm:w-[250px] lg:w-[350px] xl:w-[400px]">
              <p className="text-green-900 text-3xl md:text-4xl font-bold text-center">
                Wind Engineering
              </p>
              <div className=''>
              <p className='pt-5 text-center text-lg w-[150px] sm:w-[250px] lg:w-[350px] xl:w-[400px]'>
              Harnessing the power of wind with precision and innovation, our wind engineering services deliver cutting-edge solutions for turbine performance, structural design, and resource optimization.
              </p>
              </div>
              <div className='px-[75px]'>
              <img className='pt-5' src={`${process.env.PUBLIC_URL}/WindroseAnimation.gif`} alt="service"/>
              </div>
            </div>
            <div className="w-[150px] sm:w-[250px] lg:w-[350px] xl:w-[400px]">
              <p className="text-green-900 text-3xl md:text-4xl font-bold text-center">
                T&I Engineering
              </p>
              <div className=''>
              <p className='pt-5 text-center text-lg w-[150px] sm:w-[250px] lg:w-[350px] xl:w-[400px]'>
              Ensuring excellence at every step, our T&I engineering services provide rigorous testing, thorough inspections, and quality assurance to guarantee safe, reliable, and compliant energy solutions.
              </p>
              </div>
              <video
                className='pt-5'
                loop
                autoPlay
                muted
                playsInline>
                <source src={`${process.env.PUBLIC_URL}/about_vid3.mp4`} type="video/mp4"/>
              </video>
            </div>
          </div>
          <div className='flex justify-center z-10'>
          <a href='/service'><button className="text-black text-4xl rounded-lg border-2 border-black p-3">Explore Our Services </button></a>
          </div> 
          <AutoPlaySilentVideo 
                className="absolute inset-0 w-full h-full object-cover object-cover opacity-20 z-0" 
                video={`${process.env.PUBLIC_URL}/landing_video3.mp4`} 
        />
        </div>
        <div className="flex w-full sm:h-[100px] h-[100px] bg-green-800 flex-cols justify-between">
          <div className='p-[20px]'>
          <img className='h-[50px]' src={`${process.env.PUBLIC_URL}/EnergenecoLOGOcolored.png`} alt="service" />
          </div>
          <div className='flex gap-[20px] items-end'>
          <p className="text-white">Cypresss, TX, USA</p>
          <p className="text-white">@2024 Energeneco LLC. All rights reserved.</p>
          </div>
          <div className="flex space-x-4 items-center p-3">
      {socialLinks.map((social, index) => (
        <a
          key={index}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
          className={`w-12 h-12 flex items-center justify-center rounded-full text-white bg-white transition duration-300`}
          aria-label={social.name}
        >
          {social.svg}
        </a>
      ))}
          </div>
        </div>


      </div>
  );
}


function App() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home_alt />} />  
          <Route path="/about" element={<About />} />  
          <Route path="/client" element={<Client />} />  
          <Route path="/contact" element={<Contact />} />  
          <Route path="/employee" element={<Employee />} />  
          <Route path="/service" element={<Service />} />  
        </Routes>
      </div>
    </Router>
  );
}

export default App;

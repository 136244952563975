import "./VideoCarousel.css"
import React from "react";
import { Carousel, CarouselCaption } from "react-bootstrap";
import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.css"

const VideoCarousel = () => {
    const videoProperties = [
        {
            id: 1,
            title: "Precision Engineering for Wind Development",
            subtitle: "Empowering wind projects with meticulous engineering and unparalleled precision, driving success from concept to completion.",
            backgroundProp: "rounded-lg p-2 bg-blue-900 bg-opacity-80",
            src: `${process.env.PUBLIC_URL}/Carousel_1.mp4`,
        },
        {
            id: 2,
            title: "Sustainable Solutions",
            subtitle: "Transforming energy landscapes with customized strategies that prioritize efficiency, innovation, and environmental stewardship.",
            backgroundProp: "rounded-lg p-2 bg-green-800 bg-opacity-80",
            src: `${process.env.PUBLIC_URL}/Carousel_2.mp4`,
        },
        {
            id: 3,
            title: "Innovative Leaders",
            subtitle: "Paving the way for future energy development",
            backgroundProp: "rounded-lg p-2 bg-blue-900 bg-opacity-80",
            src: `${process.env.PUBLIC_URL}/Carousel_3.mp4`,
        },
        {
            id: 4,
            title: "A New Frontier",
            subtitle: "Exploring innovative pathways to harness the power of wind and solar energy and redefine the future of sustainable development.",
            backgroundProp: "rounded-lg p-2 bg-green-800 bg-opacity-80",
            src: `${process.env.PUBLIC_URL}/Carousel_4.mp4`,
        },
        {
            id: 5,
            title: "Smooth Transitions, Strong Foundations",
            subtitle: "From transportation to installation, we ensure every component is handled with precision, laying the groundwork for reliable and efficient wind energy projects.",
            backgroundProp: "rounded-lg p-2 bg-yellow-200 bg-opacity-70",
            src: `${process.env.PUBLIC_URL}/Carousel_5.mp4`,
        },
        {
            id: 6,
            title: "Maritime Engineering with Unmatched Accuracy",
            subtitle: "Our engineering expertise ensures unparalleled accuracy in maritime solutions.",
            backgroundProp: "rounded-lg p-2 bg-yellow-200 bg-opacity-70",
            src: `${process.env.PUBLIC_URL}/Carousel_6.mp4`,
        },
        {
            id: 7,
            title: "Heavy Lift Engineering",
            subtitle: "Specialized services for Heavy Lift Engineering with unparalleled accuracy",
            backgroundProp: "rounded-lg p-2 bg-yellow-200 bg-opacity-70",
            src: `${process.env.PUBLIC_URL}/Carousel_7.mp4`,
        },
    ]
    return (
        <div className="carousel-container">
            <Carousel>
                {videoProperties.map((videoObj) => {
                    return (
                        <Carousel.Item key={videoObj.id}>
                            <div className="video-wrapper">
                                <ReactPlayer
                                    url={videoObj.src}
                                    controls={false}
                                    playing={true}
                                    muted={true}
                                    loop={true} 
                                    width="100%" // Full width
                                    height="100%"
                                /> 
                            </div>
                            
                            <CarouselCaption className="">
                                <div className={videoObj.backgroundProp}>
                                    <h2>{videoObj.title}</h2>
                                    <p>{videoObj.subtitle}</p>
                                    <button className="rounded-lg border-2 border-white p-2 opacity-100"><a>learn more</a></button>
                                </div>
                                
                            </CarouselCaption>
                        </Carousel.Item>
                    )
                })}
            </Carousel>
        </div>
    );
};

export default VideoCarousel;